.layout-topbar {
    position: fixed;
    // height: 5rem;
    z-index: 997;
    left: 0;
    top: 0;
    width: 100%;
    padding: 0 2rem;
    background-color: var(--surface-card);
    transition: left $transitionDuration;
    display: flex;
    align-items: center;
    box-shadow: 0px 3px 5px rgba(0,0,0,.02), 0px 0px 2px rgba(0,0,0,.05), 0px 1px 4px rgba(0,0,0,.08);

    .layout-topbar-logo {
        // display: flex;
        align-items: center;
        color: var(--surface-900);
        font-size: 1.5rem;
        font-weight: 500;
        width: 245px;
        border-radius: 12px;

        img {
            margin-right: 0.5rem;
            width: 220px;
        }

        &:focus {
            @include focused();
        }
    }

    .layout-topbar-button {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        position: relative;
        color: var(--text-color-secondary);
        border-radius: 50%;
        width: 3rem;
        height: 3rem;
        cursor: pointer;
        transition: background-color $transitionDuration;

        &:hover {
            color: var(--text-color);
            background-color: var(--surface-hover);
        }

        &:focus {
            @include focused();
        }

        i {
            font-size: 1.5rem;
        }

        span {
            font-size: 1rem;
            display: none;
        }
    }

    .layout-menu-button {
        margin-left: 0;
    }

    .layout-topbar-menu-button {
        display: none;

        i {
            font-size: 1.25rem;
        }
    }

    .layout-topbar-menu {
        margin: 0 0 0 auto;
        padding: 0;
        list-style: none;
        display: block;

        .layout-topbar-button {
            margin-left: 1rem;
        }
    }
}

@media (max-width: 991px) {
    .layout-topbar {
        justify-content: space-between;

        .layout-topbar-logo {
            width: auto;
            order: 2;
        }

        .layout-menu-button {
            margin-left: 0;
            order: 1;
        }

        .layout-topbar-menu-button {
            display: inline-flex;
            margin-left: 0;
            order: 3;
        }

        .layout-topbar-menu {
            margin-left: 0;
            position: absolute;
            flex-direction: column;
            background-color: var(--surface-overlay);
            box-shadow: 0px 3px 5px rgba(0,0,0,.02), 0px 0px 2px rgba(0,0,0,.05), 0px 1px 4px rgba(0,0,0,.08);
            border-radius: 12px;
            padding: 1rem;
            right: 2rem;
            top: 5rem;
            min-width: 15rem;
            display: none;
            -webkit-animation: scalein 0.15s linear;
            animation: scalein 0.15s linear;

            &.layout-topbar-menu-mobile-active {
                display: block
            }

            .layout-topbar-button {
                margin-left: 0;
                display: flex;
                width: 100%;
                height: auto;
                justify-content: flex-start;
                border-radius: 12px;
                padding: 1rem;

                i {
                    font-size: 1rem;
                    margin-right: .5rem;
                }

                span {
                    font-weight: medium;
                    display: block;
                }
            }
        }
    }
}

.navIcon{
    margin-bottom: 0px;
}
ul.navIcon li{
	display: inline-block;
	margin-right: 10px;
}
ul.navIcon li:last-child{
	margin-right: 0px;
}
ul.navIcon li a{
	display: inherit;
    padding: 8px 17px;
    font-size: 14px;
    color: #7364DB;
    border: 2px solid #DBDBDB;
    border-radius: 7px;
    font-weight: 600;
}
ul.navIcon li a.activeMe, ul.navIcon li a:hover{
	background-color: #7364DB;
    border: 2px solid #7364DB;
    color: #fff;
}
.innerHeader{
	// background-color: #fff;
	// box-shadow: 0px 3px 10px rgba(158, 158, 158, 0.16);
    // margin-bottom: 20px;
	padding: 7px 0%;
}
.LogoWidth{
	max-width:100%;
}
.noRightPadding{
    padding-right: 0px;
}
.noLeftPadding{
    padding-left: 0px;
}
.p-sidebar-right {
    width: 300px !important;
}
// .p-component-overlay{
//     z-index: 1 !important;
// }
.sideBar {
    color: #000;
    font-weight: 500;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.avatarImg img {
    width: 70px;
    border-radius: 50%;
}
.logoutbtn button {
    background-color: #6366f1;
    color: #fff;
    padding: 10px 27px;
    margin-top: 15px;
    border: none;
    border-radius: 23px;
}
.contactBtn{
    border: 1.5px solid #8F83E2 !important;
    color: #7364DB !important;
    display: block;
    font-size: 16px;
	background-color: #8F83E2 !important;
    color: #fff !important;
    display: inline-block;
    vertical-align: middle;
    padding: 8px 18px;
    border-radius: 5px;
    font-weight: 600;
}
.contactBtn:hover{
	background-color: #7364DB !important;
	border: 1.5px solid #7364DB !important;
	color: #fff !important;
}
.layout-topbar .layout-topbar-menu{
    display: inline-block;
    vertical-align: middle;
}
.layout-topbar .layout-menu-button{
    margin-right: 15px;
}
.layout-topbar .layout-topbar-button:focus{
    box-shadow: none;
}