.layout-footer {
    transition: margin-left $transitionDuration;
    // display: flex;
    align-items: center;
    justify-content: center;
    // padding-top: 0;
    // border-top: 1px solid var(--surface-border);
}
.footerInner{
	border-top: 1px solid #DBDBDB;
	background-color: #fff;
    font-size: 14px;
    padding: 18px 0;
    color: #707070;
    padding-left: 37px;
}
.footerInner p{
	margin: 0px;
}
.footerInner p a{
	text-decoration: underline;
}
.noLeftPadding{
    padding-left: 0px;
}