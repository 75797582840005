/* You can add global styles to this file, and also import other style files */
$gutter: 1rem; //for primeflex grid system
@import "assets/layout/styles/layout/layout.scss";
/* PrimeNG */
@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";
@import 'ngx-toastr/toastr';



// ::ng-deep .acceptbutton {
//     background: #4F46E5!important;

// }

.img-bar {
  height: 22px;
}
.btn-right {
  float: right;
}
.img-logo {
  width: 33px;
}

.addbtn {
    background-color: #3B1EAE;
    color: white;
    border-radius: 35px;
    font-size: 14px;
    border: none;
    padding: 8px 25px;
  }

  .cancelbtn {
    padding: 8px 25px;
    border-radius: 35px;
    background: white;
    border: 1px solid #3b1eae;
    color: black;

  }

